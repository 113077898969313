<template>
    <div class="columns" style="user-select: none;">
        <div class="column is-quarter" />
        <div
            class="column is-two-thirds"
            style="margin-left: 16px; margin-right: 16px">
            <div
                id="welcome-inner"
                class="notification is-info"
                style="background-color: #7ebd9c">
                Stitching for Good is the Girl Scout Gold Award project of
                Nicole, an Ambassador scout in Colorado. This website was
                created with the goal of providing one central location for
                creative individuals to find charities they can craft for. As
                crafters, we often have a desire to create, but finding a
                recipient can be difficult. My solution was to create Stitching
                for Good, which allows you to browse charities seeking your
                creative talents to help those in need.
            </div>
        </div>
        <div class="column is-quarter" />
    </div>
    <div class="columns" style="user-select: none;">
        <div class="column is-quarter" />
        <div class="column is-two-thirds" style="margin-left: 16px; margin-right: 16px">
          <a href="mailto:nicole.e.mccloskey@gmail.com?subject=Stitching for Good Change Request">Suggest A Change</a>
          <div>Request that charity information be changed or added. Please include your name, email, and the name of your organization.</div>
        </div>
        <div class="column is-quarter" />
    </div>
</template>
